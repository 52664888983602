// @ts-nocheck
const resource = {
  "account-manager.message.info.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are still collecting the information of your account manager."])},
  "account-manager.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your sales consultant"])},
  "add-to-list.action.create-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "add-to-list.action.create-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new list"])},
  "add-to-list.action.manage-lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage lists"])},
  "add-to-list.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any lists yet."])},
  "add-to-list.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product has been added to the list."])},
  "add-to-list.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "add-to-list.placeholder.new-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the list"])},
  "add-to-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to list"])},
  "auth.action.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "auth.action.forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "auth.action.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "auth.label.already-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already an account?"])},
  "auth.label.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "auth.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
  "auth.label.no-account-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account yet?"])},
  "auth.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "auth.label.signed-in-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed in as"])},
  "auth.label.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "auth.label.sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "auth.message.error.invalid-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The credentials you submitted are invalid. Please try again."])},
  "auth.message.error.sign-in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An error occurred while signing in. Please try again. (Error code: ", _interpolate(_named("ErrorCode")), ")"])},
  "cart-checkout.action.add-cart-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as list"])},
  "cart-checkout.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with checkout"])},
  "cart-checkout.action.remove-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty cart"])},
  "cart-checkout.info.price-no-excise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your price is exempt from excise duty."])},
  "cart-checkout.remove-cart.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty cart"])},
  "cart-checkout.remove-cart.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove all the items in the cart?"])},
  "cart-checkout.remove-cart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty cart"])},
  "cart-checkout.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove product"])},
  "cart-checkout.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete product from cart?"])},
  "cart-checkout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shopping cart"])},
  "cart-confirmation.action.back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
  "cart-confirmation.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order #", _interpolate(_named("OrderId")), " has been received. As soon as it has been processed, you will receive confirmation by e-mail."])},
  "cart-confirmation.text.payment-pending": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order #", _interpolate(_named("OrderId")), " has been received. As soon as it has been paid and processed, you will receive confirmation by e-mail."])},
  "cart-confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your order!"])},
  "cart-logistics.action.back-to-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to order"])},
  "cart-logistics.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with checkout"])},
  "cart-logistics.delivery.message.error.update-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not update delivery address."])},
  "cart-logistics.delivery.option.new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New address"])},
  "cart-logistics.delivery.option.select-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an address"])},
  "cart-logistics.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "cart-logistics.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "cart-logistics.label.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery address"])},
  "cart-logistics.label.delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date "])},
  "cart-logistics.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
  "cart-logistics.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "cart-logistics.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name "])},
  "cart-logistics.label.notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "cart-logistics.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "cart-logistics.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
  "cart-logistics.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
  "cart-logistics.label.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup date"])},
  "cart-logistics.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "cart-logistics.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reference "])},
  "cart-logistics.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "cart-logistics.option.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery "])},
  "cart-logistics.option.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup "])},
  "cart-logistics.shipping-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method"])},
  "cart-logistics.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your preferences"])},
  "cart-logistics.title.pickup-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup at warehouse of Vanuxeem"])},
  "cart-payment.action.back-to-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to review"])},
  "cart-payment.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "cart-payment.message.error.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not create payment. Please try again later."])},
  "cart-payment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "cart-review.action.back-to-logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to logistics preferences"])},
  "cart-review.action.continue-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm my order"])},
  "cart-review.action.continue-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to payment"])},
  "cart-review.price-update.notification.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of one or more items in your cart has changed. Please review your cart and continue the checkout."])},
  "cart-review.price-update.notification.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price update"])},
  "cart-review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of your order"])},
  "cart-steps.checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "cart-steps.logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
  "cart-steps.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "cart-steps.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "cart-totals.label.deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "cart-totals.label.total-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (excl VAT)"])},
  "cart-totals.label.total-incl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (incl VAT)"])},
  "cart-totals.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
  "cart-totals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
  "catalog.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "catalog.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting the filters or write a different search term. Alternatively, go back home."])},
  "catalog.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! No products found for your search."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog"])},
  "catalog.title.search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "common.action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "common.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "common.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "common.title.not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "favorites.action.add-list-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter liste au panier"])},
  "favorites.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not add to cart."])},
  "favorites.message.info.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list does not have any products yet."])},
  "favorites.message.success.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List was added to cart."])},
  "favorites.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove product"])},
  "favorites.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete product from list?"])},
  "favorites.remove-item.title (duplicate)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete product from list?"])},
  "favorites.remove-list.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "favorites.remove-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove list?"])},
  "filters.action.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "filters.action.clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear my selection"])},
  "filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "filters.label.search-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
  "filters.multi-select.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search term"])},
  "filters.multi-select.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results for"])},
  "filters.multi-select.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select..."])},
  "filters.multi-select.placeholder.search-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be an e-mail address."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This date is invalid."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
  "header.title.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "invoices.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount (VAT incl)"])},
  "invoices.label.document-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date"])},
  "invoices.label.document-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
  "invoices.label.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "invoices.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "invoices.label.show-unpaid-invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the unpaid invoices"])},
  "invoices.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "invoices.label.status-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "invoices.label.status-unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
  "lastest-invoices.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all invoices"])},
  "lastest-orders.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all orders"])},
  "mail.activation.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
  "mail.activation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail address"])},
  "mail.activation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your Vanuxeem account by clicking the link below and setting your password."])},
  "mail.activation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your Vanuxeem account"])},
  "mail.order-confirmation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your order and will process it soon."])},
  "mail.order-confirmation.outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your order."])},
  "mail.order-confirmation.reference-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "mail.order-confirmation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your online order"])},
  "mail.price-request.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has requested the price for a product."])},
  "mail.price-request.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Request"])},
  "mail.registration.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une inscription a été demandée via le site web avec les détails suivants."])},
  "mail.registration.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New registration"])},
  "mail.reset.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "mail.reset.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail address"])},
  "mail.reset.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset was requested for your account via our website. Click the button below to reset your password. If you did not make this request, please ignore this e-mail."])},
  "mail.reset.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "orders.label.amount-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount excl. VAT"])},
  "orders.label.delivery-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery note"])},
  "orders.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "orders.label.empties-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empties (excl. VAT)"])},
  "orders.label.order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
  "orders.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "orders.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "orders.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "orders.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reference"])},
  "orders.label.sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbr."])},
  "orders.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "orders.label.status-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "orders.label.status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "orders.label.status-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
  "orders.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (incl. VAT)"])},
  "orders.message.info.no-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No lines found."])},
  "orders.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing matches your search."])},
  "orders.placeholder.search-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References, ..."])},
  "product.action.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cart"])},
  "product.action.add-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to list"])},
  "product.action.request-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request price"])},
  "product.attribute.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product code"])},
  "product.attribute.tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
  "product.label.no-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price missing"])},
  "product.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "product.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "product.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "product.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not add to cart."])},
  "product.message.success.add-to-cart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ProductName")), " was added to cart."])},
  "product.price-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price on request"])},
  "product.product-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product on order"])},
  "product-sorting.option.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
  "product-sorting.option.relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevance"])},
  "product-sorting.option.supplier-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product code"])},
  "product-sorting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "promotion.free-product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free product"])},
  "promotion.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "promotion.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting the filters. Alternatively, go back home."])},
  "promotion.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No promotions found"])},
  "promotion.per": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with purchase of"])},
  "promotion.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
  "register.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request access"])},
  "register.benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account and enjoy many benefits"])},
  "register.benefits.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order online quickly and easily"])},
  "register.benefits.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your personal discounts"])},
  "register.benefits.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your orders at a glance"])},
  "register.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "register.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a Vanuxeem account, all your information is kept in one place. Please note that only professionals are allowed to register."])},
  "register.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "register.label.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "register.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "register.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "register.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "register.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "register.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "register.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "register.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "register.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
  "register.message.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your request and will process it shortly."])},
  "register.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a new account"])},
  "request-price.action.send-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send request"])},
  "request-price.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send a price request for this product?"])},
  "request-price.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent and will be handled shortly."])},
  "request-price.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])},
  "request-price.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])},
  "reset-request.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "reset-request.instruction.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail address here and click on 'New password'."])},
  "reset-request.instruction.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We send you an email to set a new password."])},
  "reset-request.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
  "reset-request.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "search-suggestions.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while searching for suggestions."])},
  "search-suggestions.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation, references, brands, etc."])},
  "selection.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "selection.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are working hard on making sure you have the good selection of products."])},
  "selection.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products found"])},
  "selection.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My selection"])}
}
export default resource