// @ts-nocheck
const resource = {
  "account-manager.message.info.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We verzamelen nog steeds de gegevens van je vertegenwoordiger."])},
  "account-manager.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw contactpersoon"])},
  "add-to-list.action.create-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken"])},
  "add-to-list.action.create-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuwe lijst"])},
  "add-to-list.action.manage-lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijsten beheren"])},
  "add-to-list.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt nog geen lijsten."])},
  "add-to-list.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product werd toegevoegd aan de lijst."])},
  "add-to-list.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst"])},
  "add-to-list.placeholder.new-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de lijst"])},
  "add-to-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan de lijst toevoegen"])},
  "auth.action.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een account"])},
  "auth.action.forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "auth.action.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "auth.label.already-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al een account?"])},
  "auth.label.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een account"])},
  "auth.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "auth.label.no-account-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen account?"])},
  "auth.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "auth.label.signed-in-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingelogd als"])},
  "auth.label.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "auth.label.sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "auth.message.error.invalid-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De door u ingediende inloggegevens zijn ongeldig. Probeer het opnieuw."])},
  "auth.message.error.sign-in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er is een fout opgetreden tijdens het inloggen. Probeer het opnieuw. (Foutcode: ", _interpolate(_named("ErrorCode")), ")"])},
  "cart-checkout.action.add-cart-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar als lijst"])},
  "cart-checkout.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn bestelling valideren"])},
  "cart-checkout.action.remove-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen leegmaken"])},
  "cart-checkout.info.price-no-excise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw prijs is vrijgesteld van accijnzen."])},
  "cart-checkout.remove-cart.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandje legenen"])},
  "cart-checkout.remove-cart.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u alle items in het winkelwagentje wilt verwijderen?"])},
  "cart-checkout.remove-cart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandje legenen"])},
  "cart-checkout.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verwijderen"])},
  "cart-checkout.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verwijderen uit mandje?"])},
  "cart-checkout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw winkelwagen"])},
  "cart-confirmation.action.back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de startpagina"])},
  "cart-confirmation.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je bestelling #", _interpolate(_named("OrderId")), " is ontvangen. Zodra de bestelling is verwerkt, ontvang je een bevestiging per e-mail."])},
  "cart-confirmation.text.payment-pending": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw bestelling #", _interpolate(_named("OrderId")), " is ontvangen. Zodra deze is betaald en verwerkt, ontvang je een bevestiging per e-mail."])},
  "cart-confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor uw bestelling!"])},
  "cart-logistics.action.back-to-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar bestelling"])},
  "cart-logistics.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling bevestigen"])},
  "cart-logistics.delivery.message.error.update-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onmogelijk om het leveringsadres te wijzigen."])},
  "cart-logistics.delivery.option.new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw adres"])},
  "cart-logistics.delivery.option.select-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een adres"])},
  "cart-logistics.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
  "cart-logistics.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "cart-logistics.label.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadres"])},
  "cart-logistics.label.delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsdatum"])},
  "cart-logistics.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "cart-logistics.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "cart-logistics.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "cart-logistics.label.notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaar"])},
  "cart-logistics.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "cart-logistics.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optioneel"])},
  "cart-logistics.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gsmnummer"])},
  "cart-logistics.label.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophalingsdatum"])},
  "cart-logistics.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "cart-logistics.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw referentie"])},
  "cart-logistics.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
  "cart-logistics.option.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering"])},
  "cart-logistics.option.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophaling"])},
  "cart-logistics.shipping-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsmethode"])},
  "cart-logistics.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw voorkeuren"])},
  "cart-logistics.title.pickup-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophaling bij depot van Vanuxeem"])},
  "cart-payment.action.back-to-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar overzicht"])},
  "cart-payment.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalen"])},
  "cart-payment.message.error.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen betaling aanmaken. Probeer het later nog eens."])},
  "cart-payment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
  "cart-review.action.back-to-logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar logistieke voorkeuren"])},
  "cart-review.action.continue-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling doorsturen"])},
  "cart-review.action.continue-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga door met betalen"])},
  "cart-review.price-update.notification.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De prijs van een of meer artikelen in je winkelwagentje is gewijzigd. Controleer uw winkelwagentje en ga verder met afrekenen."])},
  "cart-review.price-update.notification.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijsaanpassing"])},
  "cart-review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting van uw bestelling"])},
  "cart-steps.checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "cart-steps.logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering"])},
  "cart-steps.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
  "cart-steps.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
  "cart-totals.label.deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeggoed"])},
  "cart-totals.label.total-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal (excl BTW)"])},
  "cart-totals.label.total-incl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal (incl BTW)"])},
  "cart-totals.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW"])},
  "cart-totals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankoopdetails"])},
  "catalog.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
  "catalog.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer de filters aan te passen of schrijf een andere zoekterm. U kunt ook teruggaan naar de startpagina."])},
  "catalog.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Geen producten gevonden voor uw zoekopdracht."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogus"])},
  "catalog.title.search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekresultaten"])},
  "common.action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "common.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "common.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
  "common.title.not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gevonden"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
  "favorites.action.add-list-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter liste au panier"])},
  "favorites.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kon niet aan winkelwagentje toevoegen."])},
  "favorites.message.info.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze lijst bevat nog geen producten."])},
  "favorites.message.success.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst is toegevoegd aan winkelwagen."])},
  "favorites.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verwijderen"])},
  "favorites.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verwijderen uit lijst?"])},
  "favorites.remove-item.title (duplicate)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verwijderen uit lijst?"])},
  "favorites.remove-list.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
  "favorites.remove-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst verwijderen?"])},
  "filters.action.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
  "filters.action.clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder mijn selectie"])},
  "filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaten"])},
  "filters.label.search-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op"])},
  "filters.multi-select.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een zoekterm in te geven"])},
  "filters.multi-select.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten voor"])},
  "filters.multi-select.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer..."])},
  "filters.multi-select.placeholder.search-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])},
  "filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een e-mail adres te zijn."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze datum is ongeldig."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht."])},
  "header.title.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "invoices.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid (incl BTW)"])},
  "invoices.label.document-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur datum"])},
  "invoices.label.document-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur nummer"])},
  "invoices.label.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur"])},
  "invoices.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "invoices.label.show-unpaid-invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon de onbetaalde facturen"])},
  "invoices.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "invoices.label.status-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald"])},
  "invoices.label.status-unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbetaald"])},
  "lastest-invoices.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon alle facturen"])},
  "lastest-orders.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon alle bestellingen"])},
  "mail.activation.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activeren"])},
  "mail.activation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres"])},
  "mail.activation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer je Vanuxeem account door op de onderstaande link te klikken en je wachtwoord in te stellen."])},
  "mail.activation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw Vanuxeem account activeren"])},
  "mail.order-confirmation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben je bestelling ontvangen en zullen deze binnenkort verwerken."])},
  "mail.order-confirmation.outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor uw bestelling."])},
  "mail.order-confirmation.reference-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelnummer"])},
  "mail.order-confirmation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw online bestelling"])},
  "mail.price-request.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has requested the price for a product."])},
  "mail.price-request.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijsaanvraag"])},
  "mail.registration.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une inscription a été demandée via le site web avec les détails suivants."])},
  "mail.registration.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe registratie"])},
  "mail.reset.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord instellen"])},
  "mail.reset.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres"])},
  "mail.reset.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via onze website werd een wachtwoord reset aangevraagd voor uw account. Klik op de onderstaande knop om uw wachtwoord te resetten. Indien u deze aanvraag niet heeft gedaan, gelieve deze e-mail te negeren."])},
  "mail.reset.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je wachtwoord opnieuw instellen"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "orders.label.amount-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag excl. BTW"])},
  "orders.label.delivery-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsbon"])},
  "orders.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
  "orders.label.empties-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeggoed (excl. BTW)"])},
  "orders.label.order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum bestelling"])},
  "orders.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelnummer"])},
  "orders.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenheid"])},
  "orders.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "orders.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw referentie"])},
  "orders.label.sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
  "orders.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "orders.label.status-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
  "orders.label.status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In uitvoering"])},
  "orders.label.status-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geleverd"])},
  "orders.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal (incl. BTW)"])},
  "orders.message.info.no-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen lijnen gevonden."])},
  "orders.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets komt overeen met je zoekopdracht."])},
  "orders.placeholder.search-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenties, ..."])},
  "product.action.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar mandje"])},
  "product.action.add-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan lijst"])},
  "product.action.request-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs aanvragen"])},
  "product.attribute.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productcode"])},
  "product.attribute.tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW"])},
  "product.label.no-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs ontbreekt"])},
  "product.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenheid"])},
  "product.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "product.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "product.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kon niet aan winkelwagentje toevoegen."])},
  "product.message.success.add-to-cart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ProductName")), " is toegevoegd aan winkelwagen."])},
  "product.price-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs op aanvraag"])},
  "product.product-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product op bestelling"])},
  "product-sorting.option.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product naam"])},
  "product-sorting.option.relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevantie"])},
  "product-sorting.option.supplier-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product code"])},
  "product-sorting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteren"])},
  "promotion.free-product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis product"])},
  "promotion.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
  "promotion.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer de filters aan te passen. U kunt ook teruggaan naar de startpagina."])},
  "promotion.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen promoties gevonden"])},
  "promotion.per": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bij aankoop van"])},
  "promotion.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoties"])},
  "register.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang aanvragen"])},
  "register.benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een account aan en geniet van vele voordelen"])},
  "register.benefits.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestel snel en eenvoudig online"])},
  "register.benefits.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk uw persoonlijke kortingen"])},
  "register.benefits.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volg uw bestellingen in één oogopslag"])},
  "register.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "register.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met een Vanuxeem-account worden al uw gegevens op één plaats bewaard. Houd er rekening mee dat alleen professionals zich kunnen registreren."])},
  "register.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeente"])},
  "register.label.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
  "register.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "register.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "register.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "register.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "register.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
  "register.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "register.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
  "register.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Btw-nummer"])},
  "register.message.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij hebben uw aanvraag ontvangen en zullen deze binnenkort verwerken."])},
  "register.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuw account aan"])},
  "request-price.action.send-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvraag verzenden"])},
  "request-price.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u een prijsaanvraag voor dit product versturen?"])},
  "request-price.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent and will be handled shortly."])},
  "request-price.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])},
  "request-price.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs aanvragen"])},
  "reset-request.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
  "reset-request.instruction.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier uw e-mailadres in en klik op 'Nieuw wachtwoord'."])},
  "reset-request.instruction.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij sturen u een e-mail om een ​​nieuw wachtwoord in te stellen."])},
  "reset-request.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "reset-request.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten"])},
  "search-suggestions.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while searching for suggestions."])},
  "search-suggestions.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benaming, referenties, merken, enz."])},
  "selection.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
  "selection.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We werken er hard aan om ervoor te zorgen dat je een goede selectie producten hebt."])},
  "selection.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden"])},
  "selection.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn selectie"])}
}
export default resource