// @ts-nocheck
const resource = {
  "account-manager.message.info.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous continuons à collecter les informations de votre représentant."])},
  "account-manager.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre personne de contact"])},
  "add-to-list.action.create-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "add-to-list.action.create-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer nouvelle liste"])},
  "add-to-list.action.manage-lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les listes"])},
  "add-to-list.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de listes."])},
  "add-to-list.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit ajouté à la liste"])},
  "add-to-list.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
  "add-to-list.placeholder.new-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la liste"])},
  "add-to-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la liste"])},
  "auth.action.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "auth.action.forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "auth.action.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "auth.label.already-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà un compte?"])},
  "auth.label.create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "auth.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "auth.label.no-account-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte ?"])},
  "auth.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "auth.label.signed-in-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté en tant que"])},
  "auth.label.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez-vous"])},
  "auth.label.sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "auth.message.error.invalid-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations d'identification que vous avez soumises ne sont pas valides. Veuillez réessayer."])},
  "auth.message.error.sign-in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur s'est produite lors de la connexion. Veuillez réessayer. (Code d'erreur : ", _interpolate(_named("ErrorCode")), ")"])},
  "cart-checkout.action.add-cart-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme list"])},
  "cart-checkout.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider ma commande"])},
  "cart-checkout.action.remove-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videz panier"])},
  "cart-checkout.info.price-no-excise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prix est exonéré de droits d'accises."])},
  "cart-checkout.remove-cart.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le panier"])},
  "cart-checkout.remove-cart.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer tous les articles du panier ?"])},
  "cart-checkout.remove-cart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le panier"])},
  "cart-checkout.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer produit"])},
  "cart-checkout.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le produit du panier?"])},
  "cart-checkout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
  "cart-confirmation.action.back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'acceuil"])},
  "cart-confirmation.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande #", _interpolate(_named("OrderId")), " a été bien reçue. Dès qu'elle aura été traitée, vous recevrez une confirmation par e-mail."])},
  "cart-confirmation.text.payment-pending": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande #", _interpolate(_named("OrderId")), " a été reçue. Dès qu'elle aura été payée et traitée, vous recevrez une confirmation par e-mail."])},
  "cart-confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre commande!"])},
  "cart-logistics.action.back-to-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la commande"])},
  "cart-logistics.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider ma commande"])},
  "cart-logistics.delivery.message.error.update-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour l'adresse de livraison."])},
  "cart-logistics.delivery.option.new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse"])},
  "cart-logistics.delivery.option.select-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une adresse"])},
  "cart-logistics.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "cart-logistics.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "cart-logistics.label.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "cart-logistics.label.delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison"])},
  "cart-logistics.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "cart-logistics.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "cart-logistics.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "cart-logistics.label.notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
  "cart-logistics.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
  "cart-logistics.label.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optionnel"])},
  "cart-logistics.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de GSM"])},
  "cart-logistics.label.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'enlèvement"])},
  "cart-logistics.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "cart-logistics.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence"])},
  "cart-logistics.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
  "cart-logistics.option.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
  "cart-logistics.option.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlèvement"])},
  "cart-logistics.shipping-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de livraison"])},
  "cart-logistics.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences de livraison"])},
  "cart-logistics.title.pickup-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlèvement à l'entrepôt de Vanuxeem"])},
  "cart-payment.action.back-to-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'aperçu"])},
  "cart-payment.action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
  "cart-payment.message.error.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de créer un paiement. Veuillez réessayer plus tard."])},
  "cart-payment.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "cart-review.action.back-to-logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au préférences logistiques"])},
  "cart-review.action.continue-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer ma commande"])},
  "cart-review.action.continue-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer le paiement"])},
  "cart-review.price-update.notification.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix d'un ou plusieurs articles de votre panier a changé. Veuillez vérifier votre panier et poursuivre la commande."])},
  "cart-review.price-update.notification.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustement des prix"])},
  "cart-review.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de votre commande"])},
  "cart-steps.checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "cart-steps.logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "cart-steps.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "cart-steps.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "cart-totals.label.deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidange"])},
  "cart-totals.label.total-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total HTVA"])},
  "cart-totals.label.total-incl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TVAC"])},
  "cart-totals.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
  "cart-totals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la commande"])},
  "catalog.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
  "catalog.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'ajuster les filtres ou écrivez un terme de recherche différent. Vous pouvez également revenir à la page d'accueil."])},
  "catalog.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Aucun produit trouvé pour votre recherche."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue"])},
  "catalog.title.search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche"])},
  "common.action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "common.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "common.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "common.title.not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non trouvé"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])},
  "favorites.action.add-list-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter liste au panier"])},
  "favorites.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter au panier."])},
  "favorites.message.info.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste ne contient pas encore de produits."])},
  "favorites.message.success.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste a été ajoutée au panier."])},
  "favorites.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer produit"])},
  "favorites.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le produit de la liste?"])},
  "favorites.remove-item.title (duplicate)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le produit de la liste?"])},
  "favorites.remove-list.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "favorites.remove-list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer list?"])},
  "filters.action.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
  "filters.action.clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer ma sélection"])},
  "filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
  "filters.label.search-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche sur"])},
  "filters.multi-select.message.info.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un terme de recherche"])},
  "filters.multi-select.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat pour"])},
  "filters.multi-select.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner..."])},
  "filters.multi-select.placeholder.search-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche..."])},
  "filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être une adresse e-mail."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette date n'est pas valide."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
  "header.title.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "invoices.label.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TVAC"])},
  "invoices.label.document-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de facture"])},
  "invoices.label.document-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de facture"])},
  "invoices.label.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
  "invoices.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de commande"])},
  "invoices.label.show-unpaid-invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les factures à payer"])},
  "invoices.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "invoices.label.status-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
  "invoices.label.status-unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A payer"])},
  "lastest-invoices.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les factures"])},
  "lastest-orders.action.go-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les commandes"])},
  "mail.activation.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le compte"])},
  "mail.activation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail"])},
  "mail.activation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre compte Vanuxeem en cliquant sur le lien ci-dessous et en définissant votre mot de passe."])},
  "mail.activation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre compte Vanuxeem"])},
  "mail.order-confirmation.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons reçu votre commande et la traiterons prochainement."])},
  "mail.order-confirmation.outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous remercions pour votre commande."])},
  "mail.order-confirmation.reference-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])},
  "mail.order-confirmation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande en ligne"])},
  "mail.price-request.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has requested the price for a product."])},
  "mail.price-request.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de prix"])},
  "mail.registration.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une inscription a été demandée via le site web avec les détails suivants."])},
  "mail.registration.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel enregistrement"])},
  "mail.reset.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "mail.reset.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail"])},
  "mail.reset.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une réinitialisation de mot de passe a été demandée pour votre compte via notre site web. Cliquez sur le bouton ci-dessous pour réinitialiser votre mot de passe. Si vous n'avez pas fait cette demande, veuillez ignorer cet e-mail."])},
  "mail.reset.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser votre mot de passe"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "orders.label.amount-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HTVA"])},
  "orders.label.delivery-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de livraison"])},
  "orders.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déscription"])},
  "orders.label.empties-excl-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidanges HTVA"])},
  "orders.label.order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])},
  "orders.label.order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])},
  "orders.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité"])},
  "orders.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "orders.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence"])},
  "orders.label.sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
  "orders.label.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "orders.label.status-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
  "orders.label.status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "orders.label.status-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livré"])},
  "orders.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale (TVA comprise)"])},
  "orders.message.info.no-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ligne trouvée."])},
  "orders.message.info.no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rien ne correspond à votre recherche."])},
  "orders.placeholder.search-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réferences, ..."])},
  "product.action.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au panier"])},
  "product.action.add-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au liste"])},
  "product.action.request-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander prix"])},
  "product.attribute.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de produit"])},
  "product.attribute.tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
  "product.label.no-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix inconnu"])},
  "product.label.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité"])},
  "product.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "product.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "product.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter au panier."])},
  "product.message.success.add-to-cart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ProductName")), " a été ajouté au panier."])},
  "product.price-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix sur demande"])},
  "product.product-on-demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit sur demande"])},
  "product-sorting.option.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
  "product-sorting.option.relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertinence"])},
  "product-sorting.option.supplier-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du produit"])},
  "product-sorting.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triage"])},
  "promotion.free-product.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit gratuit"])},
  "promotion.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
  "promotion.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'ajuster les filtres. Vous pouvez également revenir à la page d'accueil."])},
  "promotion.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun promotion trouvé"])},
  "promotion.per": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l'achat de"])},
  "promotion.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
  "register.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'accès"])},
  "register.benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un compte et profitez de nombreux avantages"])},
  "register.benefits.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander en ligne rapidement et facilement"])},
  "register.benefits.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir vos réductions personnelles"])},
  "register.benefits.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez vos commandes en un seul coup d'œil"])},
  "register.button.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregistrer"])},
  "register.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec un compte Vanuxeem, toutes vos infos sont conservées au même endroit. Veuillez noter que seuls les professionnels sont autorisés à s'inscrire."])},
  "register.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
  "register.label.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "register.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "register.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "register.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "register.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
  "register.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "register.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "register.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
  "register.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA"])},
  "register.message.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons reçu votre demande et la traiterons sous peu."])},
  "register.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer un nouveau compte"])},
  "request-price.action.send-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la demande"])},
  "request-price.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous envoyer une demande de prix pour ce produit ?"])},
  "request-price.message.success.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent and will be handled shortly."])},
  "request-price.message.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])},
  "request-price.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])},
  "reset-request.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "reset-request.instruction.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complétez ici votre e-mail et cliquez sur 'Nouveau mot de passe'."])},
  "reset-request.instruction.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous envoyons un email pour définir un nouveau mot de passe."])},
  "reset-request.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "reset-request.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "search-suggestions.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while searching for suggestions."])},
  "search-suggestions.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation, code article, marques, ..."])},
  "selection.no-items.action.go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
  "selection.no-items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons d'arrache-pied pour vous assurer une bonne sélection de produits."])},
  "selection.no-items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit trouvé"])},
  "selection.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma sélection"])}
}
export default resource